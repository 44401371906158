import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import Img1 from "../images/business-1.png"
import Illustration1 from "../images/illustration-1.png"
import Illustration2 from "../images/illustration-2.png"
import Illustration3 from "../images/illustration-3.png"
import bannerBg from "../images/banner-background.png";
import iconChecklist from "../images/icon-checklist.svg"
import iconDot from "../images/icon-list-dot.svg"
import Title1 from "../images/title-compete-in-todays.png"
import Title2 from "../images/title-know.png"
import { Footer } from "../components/Footer/Footer"
import { navigate } from "gatsby"
import { Link } from "gatsby";
import { useSelector } from "react-redux"
import { RootState } from "../reducers/rootReducer"
import ArrowRightCircleIcon from "@heroicons/react/24/outline/ArrowRightCircleIcon"
import DocumentCheckIcon from "@heroicons/react/24/outline/ClipboardDocumentCheckIcon"
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon"

const Business = () => {
  const userName = typeof window !== 'undefined' && localStorage.getItem('name');
  const componentRef = useRef<any>(null);
  const [signed, setSigned] = useState(false);

  const {items}: any = useSelector((state: RootState) => state.loginSlice);

  useEffect( () => {
    if(items.status) {
      setSigned(true)
    }
  },[items]);

  return (
    <Layout signed={signed}>
      <div className="bg-slate-50">
        {/* <Header isSignIn={signed} /> */}

        <section className="container px-[15px] mx-auto pb-[60px]">
          <div className="bg-path bg-cover grid gap-5 lg:gap-10 lg:grid-cols-12 pt-[40px] lg:pt-[80px] mb-[60px]" style={{ 'backgroundImage': `url(${bannerBg})` }}>
            <div className="col-span-12 mb-[20px] lg:mb-[60px]">
              <div className="lg:flex relative lg:items-center lg:justify-between">
                <div className="w-full mr-4">
                  <img className="max-w-[431px] w-full" src={Title1} alt="" />
                  <h3 className="ff-cg--bold text-[35px] leading-none mb-4">Global Business Environment</h3>
                  <p className="text-base text-[16x] leading-normal mb-4">Learning and development programs can help cultivate a more productive, fulfilled workforce.  Teaming with the right learning institution—one with the experience, agility, and industry-focused programs that align with your business needs and corporate culture—can be critical to your organization’s long-term future.</p>
                  <p className="text-base text-[16px] leading-normal mb-8">Workforce Forward, powered by University of Maryland Global Campus (UMGC), can help you develop and implement  high-quality education and training programs designed to help retain talent, fill skills gaps, and incentivize employees.</p>
                  <Link to="/contact" state={{id: "bzn"}} className="w-[220px] flex items-center justify-between bg-[#da1a32] py-[14px] px-[16px] rounded-2xl mt-[30px] mb-[60px] lg:mb-0">
                    <span className="ff-cg--semibold mr-[20px] text-white">Get Started</span>
                    <ArrowRightCircleIcon className="h-6 w-6 text-white" />
                  </Link>
                </div>
                <div className="w-full lg:w-[500px] mb-[30px] lg:mb-0">
                  <div className="bg-[#222222] py-[20px] px-[30px] rounded-2xl">
                    <h3 className="ff-cg--semibold text-white mb-4 text-[14px]">Over the last ten years</h3>
                    <div className="mb-4">
                      <p className="flex items-center">
                        <span className="ff-cg--bold text-white leading-none text-[80px] mr-2">100</span>
                        <span className="ff-cg--bold text-white leading-none text-[25px] flex items-center justify-center bg-[#da1a32] w-[35px] h-[35px] rounded-full">+</span>
                      </p>
                      <p className="text-[#cccccc] ff-cg--light text-[18px]">Organizations have formed education alliances with UMGC.</p>
                    </div>
                    <div className="mb-2">
                      <p className="flex items-center">
                        <span className="ff-cg--bold text-white leading-none text-[80px] mr-2">15</span>
                        <span className="ff-cg--bold text-white leading-none text-[15px] bg-[#da1a32] px-4 py-2 rounded-2xl">Million</span>
                      </p>
                      <p className="text-[#cccccc] ff-cg--light text-[18px]">Savings realized from these alliances.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-5 lg:gap-10 lg:grid-cols-12">
            <div className="col-span-12 mb-[20px] lg:mb-[60px]">
              <div className="md:flex md:justify-between items-start lg:items-center">
                <div className="mx-auto w-[250px] sm:w-[300px] mb-[40px] lg:mb-0 lg:w-[600px] lg:mr-[40px] md:mr-10">
                  <img src={Img1} alt="" />
                </div>
                <div className="w-full">
                  <div className="mb-10">
                    <h3 className="ff-cg--bold text-[35px] leading-none mb-4">The Online Learning Experience</h3>
                    <p className="text-base text-[20px] leading-normal">As one of the first institutions to offer online learning, we remain at the forefront of making higher education available to everyone, everywhere. Our courses are specifically created to be delivered within a fully online environment, offering the flexibility and convenience that enables your employees to arm themselves with the skills and knowledge you require.</p>
                  </div>
                  <div className="mb-4">
                    <h3 className="ff-cg--bold text-[35px] leading-none mb-4">No-Cost Digital Resources</h3>
                    <p className="text-base text-[20px] leading-normal">To enhance the online learning experience, we’ve replaced costly, bulky textbooks with <strong>no-cost digital materials</strong> for nearly every course. For most courses, students simply enroll, and their materials are waiting for them. Eliminating the task (and expense) of buying books is one more way we make it easier for employees to participate, learn, and grow.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-5 lg:gap-10 lg:grid-cols-12">
            <div className="col-span-12 mb-[20px] lg:mb-[60px]">
              <div className="lg:flex lg:justify-between items-center">
                <div className="w-full lg:w-1/2 lg:pr-[50px]">
                  <div className="flex flex-col gap-4 md:flex-row items-start md:items-center md:justify-center lg:md:justify-start mb-[40px] lg:mb-[30px]">
                    <img src={Illustration1} className="w-full max-w-[180px]" alt="" />
                    <div className="lg:ml-[40px]">
                      <p className="ff-cg--bold text-[20px] text-[#da1a32] leading-none">RECRUIT</p>
                      <h3 className="ff-cg--bold text-[50px] lg:text-[60px] leading-none">Top Talent</h3>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row items-start md:items-center md:justify-center lg:md:justify-start mb-[40px] lg:mb-[30px]">
                    <img src={Illustration2} className="md:order-2 w-full max-w-[180px]" alt="" />
                    <div className="lg:order-1 lg:mr-[40px]">
                      <p className="ff-cg--bold text-[20px] text-[#da1a32] leading-none">ENHANCE</p>
                      <h3 className="ff-cg--bold text-[50px] lg:text-[60px] leading-none">Productivity</h3>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row items-start md:items-center md:justify-center lg:md:justify-start mb-[60px] lg:mb-0">
                    <img src={Illustration3} className="w-full max-w-[220px]" alt="" />
                    <div className="lg:ml-[40px]">
                      <p className="ff-cg--bold text-[20px] text-[#da1a32] leading-none">REDUCE</p>
                      <h3 className="ff-cg--bold text-[50px] lg:text-[60px] leading-none">Turnover</h3>
                    </div>
                  </div>

                </div>
                <div className="w-full lg:w-1/2 mb-[40px] lg:mb-0">
                  <div className="bg-[#222222] py-[40px] px-[50px] rounded-2xl">
                    <h3 className="ff-cg--bold text-white leading-none text-[40px] lg:text-[80px] mr-2">DID YOU</h3>
                    <img src={Title2} className="w-full max-w-[200px] lg:max-w-[290px] mb-[30px]" alt="" />
                    <div className="mb-4">
                      <p className="text-white ff-cg--light text-[20px] lg:text-[30px] mb-[20px] leading-tight">More than 50% of HR professionals feel that skills shortages have worsened in their organizations.</p>
                      <p className="text-[#999999] ff-cg--medium text-[14px] mb-[60px]">
                        Source: “The Global Skills Shortage,” <br />
                        Society for Human Resource Management, February 5, 2019.
                      </p>
                    </div>
                    <Link to="/contact" state={{id: "bzn"}} className="max-w-[300px] w-full flex items-center justify-between bg-[#da1a32] py-[14px] px-[16px] rounded-2xl mt-[30px]">
                      <span className="ff-cg--semibold mr-[20px] text-white">Develop Your Workforce</span>
                      <ArrowRightCircleIcon className="h-6 w-6 text-white" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-5 lg:gap-10 lg:grid-cols-12">
            <div className="col-span-12 mb-[20px] lg:mb-[60px]">
              <div className="text-center mb-[50px] lg:mb-[40px]">
                <p className="flex items-center justify-center">
                  <img src={iconChecklist} className="h-10 w-10 text-black mt-[-10px] mr-2" />
                  <span className="ff-cg--bold text-[24px] leading-none">DESIGN</span>
                </p>
                <h2 className="ff-cg--bold lg:ff-cg--light text-[30px] md:text-[80px] leading-none">A Tailored Solution</h2>
              </div>
              <div className="lg:flex lg:justify-between items-start">
                <div className="hidden lg:block w-400 mr-[40px]">
                  <div className="bg-[#f5f5f5] py-[40px] px-[50px] rounded-2xl">
                    <div className="mb-4">
                      <p className="flex items-center justify-space mb-[60px]">
                        <span className="ff-cg--bold text-white leading-none text-[50px] flex items-center justify-center bg-[#da1a32] min-w-[100px] h-[100px] rounded-full mr-4">1</span>
                        <div>
                          <h3 className="ff-cg--bold text-[26px] leading-none mb-2">Discover</h3>
                          <p className="text-base text-[13px] leading-normal">Schedule a meeting with our business development team</p>
                        </div>
                      </p>
                      <p className="flex items-center justify-space mb-[60px]">
                        <span className="ff-cg--bold text-white leading-none text-[50px] flex items-center justify-center bg-[#da1a32] min-w-[100px] h-[100px] rounded-full mr-4">2</span>
                        <div>
                          <h3 className="ff-cg--bold text-[26px] leading-none mb-2">Design</h3>
                          <p className="text-base text-[13px] leading-normal">Work with us to plan and develop a solution tailored to meet the specific needs and initiatives of your organization.</p>
                        </div>
                      </p>
                      <p className="flex items-center justify-space mb-[60px]">
                        <span className="ff-cg--bold text-white leading-none text-[50px] flex items-center justify-center bg-[#da1a32] min-w-[100px] h-[100px] rounded-full mr-4">3</span>
                        <div>
                          <h3 className="ff-cg--bold text-[26px] leading-none mb-2">Deploy</h3>
                          <p className="text-base text-[13px] leading-normal">Implement the planned solution at your organization</p>
                        </div>
                      </p>
                      <p className="flex items-center justify-space">
                        <span className="ff-cg--bold text-white leading-none text-[50px] flex items-center justify-center bg-[#da1a32] min-w-[100px] h-[100px] rounded-full mr-4">4</span>
                        <div>
                          <h3 className="ff-cg--bold text-[26px] leading-none mb-2">Track Success</h3>
                          <p className="text-base text-[13px] leading-normal">Review success metrics to see how the solution is benefiting your employees and organization.</p>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="mb-10">
                    <h3 className="ff-cg--bold text-[30px] leading-none mb-4">For Your Organization</h3>
                    <p className="text-base text-[20px] leading-normal mb-4">We take a consultative approach to help ensure our education solutions fit your strategic objectives. Our goal is to understand your organization’s needs and customize a workforce development plan that can help you:</p>
                    <ul>
                      <li className="flex leading-normal mb-2"><img src={iconDot} className="h-[15px] min-w-[15px] mt-[4px] mr-2" /> Provide targeted training to fit your organization’s specific goals, job roles, and skills shortages</li>
                      <li className="flex leading-normal mb-2"><img src={iconDot} className="h-[15px] min-w-[15px] mt-[4px] mr-2" /> Recruit top talent with a plan that can make your company more attractive to high-quality candidates and create a stronger, more loyal workforce</li>
                      <li className="flex leading-normal mb-2"><img src={iconDot} className="h-[15px] min-w-[15px] mt-[4px] mr-2" /> Aid in succession planning and retain crucial employees while avoiding the costs of recruiting and training unnecessary new hires</li>
                      <li className="flex leading-normal mb-2"><img src={iconDot} className="h-[15px] min-w-[15px] mt-[4px] mr-2" /> Customize benefits to your financial parameters for tuition assistance and reimbursement</li>
                    </ul>
                  </div>
                  <div className="mb-4">
                    <h3 className="ff-cg--bold text-[30px] leading-none mb-4">For Your Employees</h3>
                    <p className="text-base text-[20px] leading-normal mb-4">Whether you want to empower your employees to complete a degree or equip them with the skills they need to grow within your organization, teaming up with Workforce Forward can help you:</p>
                    <ul>
                      <li className="flex leading-normal mb-2"><img src={iconDot} className="h-[15px] min-w-[15px] mt-[4px] mr-2" /> Reduce out-of-pocket costs for employees</li>
                      <li className="flex leading-normal mb-2"><img src={iconDot} className="h-[15px] min-w-[15px] mt-[4px] mr-2" /> Create specialized internal programs by aligning curricula to employee skills gaps</li>
                      <li className="flex leading-normal mb-2"><img src={iconDot} className="h-[15px] min-w-[15px] mt-[4px] mr-2" /> Offer career-focused coursework that can prepare your employees with skills and knowledge they can apply on the job right away</li>
                      <li className="flex leading-normal mb-2"><img src={iconDot} className="h-[15px] min-w-[15px] mt-[4px] mr-2" /> Provide access to student services to complement your employee education resources</li>
                    </ul>
                  </div>
                  <Link to="/contact" state={{id: "bzn"}} className="w-full max-w-[320px] lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[30px]">
                    <span className="ff-cg--semibold mr-[20px]">Take the Next Step</span>
                    <ArrowRightCircleIcon className="h-6 w-6" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>
      <Footer />
    </Layout>
  )
}

export default Business;
export { Head } from "../components/Layout/Head";

